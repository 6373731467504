import Firebase from 'firebase/app'
import "firebase/database"
import "firebase/storage"
// Initialize Firebase
const app = Firebase.initializeApp({
  apiKey: "AIzaSyDkibsm99MBF1-mIRUf-7eraurjmNKAL0Y",
  authDomain: "healthview-homehealth-c89m.firebaseapp.com",
  databaseURL: "https://healthview-homehealth-c89m.firebaseio.com",
  projectId: "healthview-homehealth-c89m",
  storageBucket: "healthview-homehealth-c89m.appspot.com",
  messagingSenderId: "800081952898"
});

export const firebase = Firebase
export const db = app.database()
export const contactFormRef = db.ref("contact_form")
export const employmentApplicationsListRef = db.ref('employment_applications_list')
export const employmentApplicationsRef = db.ref('employment_applications')
export const jobsRef = db.ref('jobs')

export const storageRef = firebase.storage().ref()
